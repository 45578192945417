/*
* ---------------------------------------------
* 				Icon animations
* ---------------------------------------------
*/
.ty-icon-spin {
  -moz-animation: spin 0.5s infinite linear;
  -webkit-animation: spin 0.5s infinite linear;
  animation: spin 0.5s infinite linear;
  display: inline-block;
}

.ty-icon-spin-single {
  -moz-animation: spin 0.4s 1 linear;
  -webkit-animation: spin 0.4s 1 linear;
  animation: spin 0.4s 1 linear;
  display: inline-block;
}

.ty-icon-spin-reverse {
  -moz-animation: spin-reverse 0.5s infinite linear;
  -webkit-animation: spin-reverse 0.5s infinite linear;
  animation: spin-reverse 0.5s infinite linear;
  display: inline-block;
}

.ty-icon-spin-reverse-single {
  -moz-animation: spin-reverse 0.4s 1 linear;
  -webkit-animation: spin-reverse 0.4s 1 linear;
  animation: spin-reverse 0.4s 1 linear;
  display: inline-block;
}

.ty-icon-bounce {
  -moz-animation: bounce 1s infinite ease-in-out;
  -webkit-animation: bounce 1s infinite ease-in-out;
  animation: bounce 1s infinite ease-in-out;
  display: inline-block;
}

.ty-icon-pulsate {
  -moz-animation: pulsate 1s infinite ease-in-out;
  -webkit-animation: pulsate 1s infinite ease-in-out;
  animation: pulsate 1s infinite ease-in-out;
  display: inline-block;
}

/*
* ---------------------------------------------
* 					Animations
* ---------------------------------------------
*/
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-moz-keyframes spin-reverse {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}
@-webkit-keyframes spin-reverse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}
@keyframes spin-reverse {
  0% {
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(-359deg);
    -ms-transform: rotate(-359deg);
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}
@-moz-keyframes bounce {
  50%, 100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  25%, 75% {
    -moz-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
@-webkit-keyframes bounce {
  50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  25%, 75% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
@keyframes bounce {
  50%, 100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  25%, 75% {
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
@-moz-keyframes pulsate {
  0% {
    -moz-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -moz-transform: scale(1.3);
    transform: scale(1.3);
  }
  100% {
    -moz-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate {
  0% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  100% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes flip-v {
  0% {
    -moz-transform: scaleY(1);
    transform: scaleY(1);
  }
  100% {
    -moz-transform: scaleY(-1);
    transform: scaleY(-1);
  }
}
@-webkit-keyframes flip-v {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
  100% {
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
  }
}
@keyframes flip-v {
  0% {
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
  100% {
    -moz-transform: scaleY(-1);
    -ms-transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
  }
}
@-moz-keyframes flip-h {
  0% {
    -moz-transform: scaleX(-1);
    transform: scaleX(-1);
  }
}
@-webkit-keyframes flip-h {
  0% {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
}
@keyframes flip-h {
  0% {
    -moz-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
}
@-moz-keyframes fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@font-face {
  font-family: "TrustYou Iconic";
  src: url("../fonts/trustyou-iconic.eot");
  src: url("../fonts/trustyou-iconic.eot?#iefix") format("embedded-opentype"), url("../fonts/trustyou-iconic.woff") format("woff"), url("../fonts/trustyou-iconic.ttf") format("truetype"), url("../fonts/trustyou-iconic.svg#trustyou-iconic") format("svg");
  font-weight: normal;
  font-style: normal;
}
/*
* ---------------------------------------------
* 				Icon Stacking
* ---------------------------------------------
*/
.ty-icon-stacked {
  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ty-icon-stacked > .ty-icon {
  font-size: 30px;
}
.ty-icon-stacked .badge {
  position: absolute;
  padding: 0 3px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0 2px #fff;
}
.ty-icon-stacked .badge .ty-icon {
  margin: 0;
}
.ty-icon-stacked .l-pull-left {
  transform: translateX(-33%);
}
.ty-icon-stacked .l-pull-right {
  transform: translateX(33%);
}
.ty-icon-stacked .l-top-left {
  top: 2px;
  left: 2px;
}
.ty-icon-stacked .l-top-right {
  top: 2px;
  right: 2px;
}
.ty-icon-stacked .l-bottom-left {
  bottom: 2px;
  left: 2px;
}
.ty-icon-stacked .l-bottom-right {
  bottom: 2px;
  right: 2px;
}

.ty-icon {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "TrustYou Iconic" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
}
.ty-icon.ty-icon-blank:before {
  content: " ";
}
.ty-icon.ty-icon-airplane:before {
  content: "\f101";
}
.ty-icon.ty-icon-alarm:before {
  content: "\f102";
}
.ty-icon.ty-icon-arrow-down:before {
  content: "\f103";
}
.ty-icon.ty-icon-arrow-left:before {
  content: "\f104";
}
.ty-icon.ty-icon-arrow-right:before {
  content: "\f105";
}
.ty-icon.ty-icon-arrow-up:before {
  content: "\f106";
}
.ty-icon.ty-icon-badge:before {
  content: "\f107";
}
.ty-icon.ty-icon-bed:before {
  content: "\f108";
}
.ty-icon.ty-icon-bell-check:before {
  content: "\f109";
}
.ty-icon.ty-icon-bell:before {
  content: "\f10a";
}
.ty-icon.ty-icon-book:before {
  content: "\f10b";
}
.ty-icon.ty-icon-bookmark:before {
  content: "\f10c";
}
.ty-icon.ty-icon-box:before {
  content: "\f10d";
}
.ty-icon.ty-icon-building-search:before {
  content: "\f10e";
}
.ty-icon.ty-icon-building:before {
  content: "\f10f";
}
.ty-icon.ty-icon-bullhorn:before {
  content: "\f110";
}
.ty-icon.ty-icon-bus:before {
  content: "\f111";
}
.ty-icon.ty-icon-calendar-clock:before {
  content: "\f112";
}
.ty-icon.ty-icon-calendar-search-none:before {
  content: "\f113";
}
.ty-icon.ty-icon-calendar:before {
  content: "\f114";
}
.ty-icon.ty-icon-call-bell:before {
  content: "\f115";
}
.ty-icon.ty-icon-car:before {
  content: "\f116";
}
.ty-icon.ty-icon-cards:before {
  content: "\f117";
}
.ty-icon.ty-icon-caret-down:before {
  content: "\f118";
}
.ty-icon.ty-icon-caret-left:before {
  content: "\f119";
}
.ty-icon.ty-icon-caret-right:before {
  content: "\f11a";
}
.ty-icon.ty-icon-caret-up:before {
  content: "\f11b";
}
.ty-icon.ty-icon-cart:before {
  content: "\f11c";
}
.ty-icon.ty-icon-chart-bar-alt:before {
  content: "\f11d";
}
.ty-icon.ty-icon-chart-bar:before {
  content: "\f11e";
}
.ty-icon.ty-icon-chart-line:before {
  content: "\f11f";
}
.ty-icon.ty-icon-chart-pie:before {
  content: "\f120";
}
.ty-icon.ty-icon-chart-radar:before {
  content: "\f121";
}
.ty-icon.ty-icon-check-o:before {
  content: "\f122";
}
.ty-icon.ty-icon-check:before {
  content: "\f123";
}
.ty-icon.ty-icon-checkbox-intermediate:before {
  content: "\f124";
}
.ty-icon.ty-icon-checkbox-on:before {
  content: "\f125";
}
.ty-icon.ty-icon-checkbox:before {
  content: "\f126";
}
.ty-icon.ty-icon-chevron-down:before {
  content: "\f127";
}
.ty-icon.ty-icon-chevron-left:before {
  content: "\f128";
}
.ty-icon.ty-icon-chevron-right:before {
  content: "\f129";
}
.ty-icon.ty-icon-chevron-up:before {
  content: "\f12a";
}
.ty-icon.ty-icon-child:before {
  content: "\f12b";
}
.ty-icon.ty-icon-cigar:before {
  content: "\f12c";
}
.ty-icon.ty-icon-circle:before {
  content: "\f12d";
}
.ty-icon.ty-icon-cleanliness:before {
  content: "\f12e";
}
.ty-icon.ty-icon-clock:before {
  content: "\f12f";
}
.ty-icon.ty-icon-code:before {
  content: "\f130";
}
.ty-icon.ty-icon-coffee:before {
  content: "\f131";
}
.ty-icon.ty-icon-comment-broken:before {
  content: "\f132";
}
.ty-icon.ty-icon-comment-check:before {
  content: "\f133";
}
.ty-icon.ty-icon-comment-click:before {
  content: "\f134";
}
.ty-icon.ty-icon-comment-search-none:before {
  content: "\f135";
}
.ty-icon.ty-icon-comment:before {
  content: "\f136";
}
.ty-icon.ty-icon-comments:before {
  content: "\f137";
}
.ty-icon.ty-icon-compare:before {
  content: "\f138";
}
.ty-icon.ty-icon-compass:before {
  content: "\f139";
}
.ty-icon.ty-icon-contract:before {
  content: "\f13a";
}
.ty-icon.ty-icon-copy:before {
  content: "\f13b";
}
.ty-icon.ty-icon-couple:before {
  content: "\f13c";
}
.ty-icon.ty-icon-credit-card:before {
  content: "\f13d";
}
.ty-icon.ty-icon-crown:before {
  content: "\f13e";
}
.ty-icon.ty-icon-cursor-click:before {
  content: "\f13f";
}
.ty-icon.ty-icon-cut:before {
  content: "\f140";
}
.ty-icon.ty-icon-cycling:before {
  content: "\f141";
}
.ty-icon.ty-icon-database-search:before {
  content: "\f142";
}
.ty-icon.ty-icon-database:before {
  content: "\f143";
}
.ty-icon.ty-icon-denied:before {
  content: "\f144";
}
.ty-icon.ty-icon-desktop:before {
  content: "\f145";
}
.ty-icon.ty-icon-directions:before {
  content: "\f146";
}
.ty-icon.ty-icon-distribution:before {
  content: "\f147";
}
.ty-icon.ty-icon-dollar:before {
  content: "\f148";
}
.ty-icon.ty-icon-download:before {
  content: "\f149";
}
.ty-icon.ty-icon-earth:before {
  content: "\f14a";
}
.ty-icon.ty-icon-email:before {
  content: "\f14b";
}
.ty-icon.ty-icon-emoji-negative:before {
  content: "\f14c";
}
.ty-icon.ty-icon-emoji-neutral:before {
  content: "\f14d";
}
.ty-icon.ty-icon-emoji-positive:before {
  content: "\f14e";
}
.ty-icon.ty-icon-euro:before {
  content: "\f14f";
}
.ty-icon.ty-icon-excel:before {
  content: "\f150";
}
.ty-icon.ty-icon-expand:before {
  content: "\f151";
}
.ty-icon.ty-icon-export:before {
  content: "\f152";
}
.ty-icon.ty-icon-facebook-alt:before {
  content: "\f153";
}
.ty-icon.ty-icon-facebook-messenger:before {
  content: "\f154";
}
.ty-icon.ty-icon-facebook:before {
  content: "\f155";
}
.ty-icon.ty-icon-family:before {
  content: "\f156";
}
.ty-icon.ty-icon-female:before {
  content: "\f157";
}
.ty-icon.ty-icon-file-add:before {
  content: "\f158";
}
.ty-icon.ty-icon-file-check:before {
  content: "\f159";
}
.ty-icon.ty-icon-file-csv:before {
  content: "\f15a";
}
.ty-icon.ty-icon-file-doc-alt:before {
  content: "\f15b";
}
.ty-icon.ty-icon-file-doc:before {
  content: "\f15c";
}
.ty-icon.ty-icon-file-edit:before {
  content: "\f15d";
}
.ty-icon.ty-icon-file-pdf-alt:before {
  content: "\f15e";
}
.ty-icon.ty-icon-file-pdf:before {
  content: "\f15f";
}
.ty-icon.ty-icon-file-ppt-alt:before {
  content: "\f160";
}
.ty-icon.ty-icon-file-ppt:before {
  content: "\f161";
}
.ty-icon.ty-icon-file-xls-alt:before {
  content: "\f162";
}
.ty-icon.ty-icon-file-xls:before {
  content: "\f163";
}
.ty-icon.ty-icon-file:before {
  content: "\f164";
}
.ty-icon.ty-icon-filter:before {
  content: "\f165";
}
.ty-icon.ty-icon-flag:before {
  content: "\f166";
}
.ty-icon.ty-icon-folder-open:before {
  content: "\f167";
}
.ty-icon.ty-icon-folder:before {
  content: "\f168";
}
.ty-icon.ty-icon-font-search:before {
  content: "\f169";
}
.ty-icon.ty-icon-food-drink:before {
  content: "\f16a";
}
.ty-icon.ty-icon-food:before {
  content: "\f16b";
}
.ty-icon.ty-icon-forward:before {
  content: "\f16c";
}
.ty-icon.ty-icon-foursquare:before {
  content: "\f16d";
}
.ty-icon.ty-icon-fruit:before {
  content: "\f16e";
}
.ty-icon.ty-icon-funnel:before {
  content: "\f16f";
}
.ty-icon.ty-icon-gauge:before {
  content: "\f170";
}
.ty-icon.ty-icon-gender:before {
  content: "\f171";
}
.ty-icon.ty-icon-gift:before {
  content: "\f172";
}
.ty-icon.ty-icon-github:before {
  content: "\f173";
}
.ty-icon.ty-icon-glass-beer:before {
  content: "\f174";
}
.ty-icon.ty-icon-glass-martini:before {
  content: "\f175";
}
.ty-icon.ty-icon-glass-wine:before {
  content: "\f176";
}
.ty-icon.ty-icon-glasses:before {
  content: "\f177";
}
.ty-icon.ty-icon-globe:before {
  content: "\f178";
}
.ty-icon.ty-icon-golf:before {
  content: "\f179";
}
.ty-icon.ty-icon-google-adwords:before {
  content: "\f17a";
}
.ty-icon.ty-icon-google-plus:before {
  content: "\f17b";
}
.ty-icon.ty-icon-google:before {
  content: "\f17c";
}
.ty-icon.ty-icon-gps:before {
  content: "\f17d";
}
.ty-icon.ty-icon-grab-alt:before {
  content: "\f17e";
}
.ty-icon.ty-icon-grab:before {
  content: "\f17f";
}
.ty-icon.ty-icon-graduation-cap:before {
  content: "\f180";
}
.ty-icon.ty-icon-grid:before {
  content: "\f181";
}
.ty-icon.ty-icon-group:before {
  content: "\f182";
}
.ty-icon.ty-icon-gym:before {
  content: "\f183";
}
.ty-icon.ty-icon-handshake:before {
  content: "\f184";
}
.ty-icon.ty-icon-headset:before {
  content: "\f185";
}
.ty-icon.ty-icon-heart-pulse:before {
  content: "\f186";
}
.ty-icon.ty-icon-heart:before {
  content: "\f187";
}
.ty-icon.ty-icon-help-o:before {
  content: "\f188";
}
.ty-icon.ty-icon-help:before {
  content: "\f189";
}
.ty-icon.ty-icon-hiking:before {
  content: "\f18a";
}
.ty-icon.ty-icon-history:before {
  content: "\f18b";
}
.ty-icon.ty-icon-home:before {
  content: "\f18c";
}
.ty-icon.ty-icon-hourglass:before {
  content: "\f18d";
}
.ty-icon.ty-icon-housekeeping:before {
  content: "\f18e";
}
.ty-icon.ty-icon-image:before {
  content: "\f18f";
}
.ty-icon.ty-icon-images:before {
  content: "\f190";
}
.ty-icon.ty-icon-inbox-empty:before {
  content: "\f191";
}
.ty-icon.ty-icon-inbox:before {
  content: "\f192";
}
.ty-icon.ty-icon-info-o:before {
  content: "\f193";
}
.ty-icon.ty-icon-info:before {
  content: "\f194";
}
.ty-icon.ty-icon-instagram:before {
  content: "\f195";
}
.ty-icon.ty-icon-key:before {
  content: "\f196";
}
.ty-icon.ty-icon-keyboard:before {
  content: "\f197";
}
.ty-icon.ty-icon-lake-house:before {
  content: "\f198";
}
.ty-icon.ty-icon-language:before {
  content: "\f199";
}
.ty-icon.ty-icon-leaf:before {
  content: "\f19a";
}
.ty-icon.ty-icon-link-external:before {
  content: "\f19b";
}
.ty-icon.ty-icon-link:before {
  content: "\f19c";
}
.ty-icon.ty-icon-list:before {
  content: "\f19d";
}
.ty-icon.ty-icon-lock:before {
  content: "\f19e";
}
.ty-icon.ty-icon-lotus:before {
  content: "\f19f";
}
.ty-icon.ty-icon-male:before {
  content: "\f1a0";
}
.ty-icon.ty-icon-map-marker:before {
  content: "\f1a1";
}
.ty-icon.ty-icon-map:before {
  content: "\f1a2";
}
.ty-icon.ty-icon-menu:before {
  content: "\f1a3";
}
.ty-icon.ty-icon-merge:before {
  content: "\f1a4";
}
.ty-icon.ty-icon-meta-review:before {
  content: "\f1a5";
}
.ty-icon.ty-icon-microphone-01:before {
  content: "\f1a6";
}
.ty-icon.ty-icon-microphone:before {
  content: "\f1a7";
}
.ty-icon.ty-icon-minus:before {
  content: "\f1a8";
}
.ty-icon.ty-icon-more-alt:before {
  content: "\f1a9";
}
.ty-icon.ty-icon-more:before {
  content: "\f1aa";
}
.ty-icon.ty-icon-move:before {
  content: "\f1ab";
}
.ty-icon.ty-icon-museum:before {
  content: "\f1ac";
}
.ty-icon.ty-icon-paper-bag:before {
  content: "\f1ad";
}
.ty-icon.ty-icon-paper-plane:before {
  content: "\f1ae";
}
.ty-icon.ty-icon-paperclip:before {
  content: "\f1af";
}
.ty-icon.ty-icon-paste:before {
  content: "\f1b0";
}
.ty-icon.ty-icon-pause-o:before {
  content: "\f1b1";
}
.ty-icon.ty-icon-pause:before {
  content: "\f1b2";
}
.ty-icon.ty-icon-pdf:before {
  content: "\f1b3";
}
.ty-icon.ty-icon-pencil:before {
  content: "\f1b4";
}
.ty-icon.ty-icon-pet:before {
  content: "\f1b5";
}
.ty-icon.ty-icon-piggy-bank:before {
  content: "\f1b6";
}
.ty-icon.ty-icon-pinterest:before {
  content: "\f1b7";
}
.ty-icon.ty-icon-play-o:before {
  content: "\f1b8";
}
.ty-icon.ty-icon-play:before {
  content: "\f1b9";
}
.ty-icon.ty-icon-plus:before {
  content: "\f1ba";
}
.ty-icon.ty-icon-powerpoint:before {
  content: "\f1bb";
}
.ty-icon.ty-icon-preview-off:before {
  content: "\f1bc";
}
.ty-icon.ty-icon-preview:before {
  content: "\f1bd";
}
.ty-icon.ty-icon-print:before {
  content: "\f1be";
}
.ty-icon.ty-icon-profile:before {
  content: "\f1bf";
}
.ty-icon.ty-icon-pulse:before {
  content: "\f1c0";
}
.ty-icon.ty-icon-pushpin:before {
  content: "\f1c1";
}
.ty-icon.ty-icon-puzzle:before {
  content: "\f1c2";
}
.ty-icon.ty-icon-qr-code:before {
  content: "\f1c3";
}
.ty-icon.ty-icon-questions:before {
  content: "\f1c4";
}
.ty-icon.ty-icon-radio-on:before {
  content: "\f1c5";
}
.ty-icon.ty-icon-radio:before {
  content: "\f1c6";
}
.ty-icon.ty-icon-redo:before {
  content: "\f1c7";
}
.ty-icon.ty-icon-refresh:before {
  content: "\f1c8";
}
.ty-icon.ty-icon-remove-o:before {
  content: "\f1c9";
}
.ty-icon.ty-icon-remove:before {
  content: "\f1ca";
}
.ty-icon.ty-icon-reply-all:before {
  content: "\f1cb";
}
.ty-icon.ty-icon-reply-pending:before {
  content: "\f1cc";
}
.ty-icon.ty-icon-reply-plus:before {
  content: "\f1cd";
}
.ty-icon.ty-icon-reply:before {
  content: "\f1ce";
}
.ty-icon.ty-icon-retweet:before {
  content: "\f1cf";
}
.ty-icon.ty-icon-revenue:before {
  content: "\f1d0";
}
.ty-icon.ty-icon-review-click:before {
  content: "\f1d1";
}
.ty-icon.ty-icon-review-clock:before {
  content: "\f1d2";
}
.ty-icon.ty-icon-review-denied:before {
  content: "\f1d3";
}
.ty-icon.ty-icon-review-distribution:before {
  content: "\f1d4";
}
.ty-icon.ty-icon-review-edit:before {
  content: "\f1d5";
}
.ty-icon.ty-icon-review-reply:before {
  content: "\f1d6";
}
.ty-icon.ty-icon-review-search-none:before {
  content: "\f1d7";
}
.ty-icon.ty-icon-review:before {
  content: "\f1d8";
}
.ty-icon.ty-icon-reviews:before {
  content: "\f1d9";
}
.ty-icon.ty-icon-ribbon:before {
  content: "\f1da";
}
.ty-icon.ty-icon-road:before {
  content: "\f1db";
}
.ty-icon.ty-icon-rocket:before {
  content: "\f1dc";
}
.ty-icon.ty-icon-room-service:before {
  content: "\f1dd";
}
.ty-icon.ty-icon-rss:before {
  content: "\f1de";
}
.ty-icon.ty-icon-running:before {
  content: "\f1df";
}
.ty-icon.ty-icon-satisfaction:before {
  content: "\f1e0";
}
.ty-icon.ty-icon-save:before {
  content: "\f1e1";
}
.ty-icon.ty-icon-search:before {
  content: "\f1e2";
}
.ty-icon.ty-icon-settings:before {
  content: "\f1e3";
}
.ty-icon.ty-icon-share:before {
  content: "\f1e4";
}
.ty-icon.ty-icon-shield:before {
  content: "\f1e5";
}
.ty-icon.ty-icon-shuffle:before {
  content: "\f1e6";
}
.ty-icon.ty-icon-sign-in:before {
  content: "\f1e7";
}
.ty-icon.ty-icon-sign-out:before {
  content: "\f1e8";
}
.ty-icon.ty-icon-signal:before {
  content: "\f1e9";
}
.ty-icon.ty-icon-single:before {
  content: "\f1ea";
}
.ty-icon.ty-icon-smartphone:before {
  content: "\f1eb";
}
.ty-icon.ty-icon-snowflake:before {
  content: "\f1ec";
}
.ty-icon.ty-icon-spinner:before {
  content: "\f1ed";
}
.ty-icon.ty-icon-star:before {
  content: "\f1ee";
}
.ty-icon.ty-icon-suitcase:before {
  content: "\f1ef";
}
.ty-icon.ty-icon-sun:before {
  content: "\f1f0";
}
.ty-icon.ty-icon-survey-alt:before {
  content: "\f1f1";
}
.ty-icon.ty-icon-survey:before {
  content: "\f1f2";
}
.ty-icon.ty-icon-swiming:before {
  content: "\f1f3";
}
.ty-icon.ty-icon-swimming:before {
  content: "\f1f4";
}
.ty-icon.ty-icon-tag:before {
  content: "\f1f5";
}
.ty-icon.ty-icon-target:before {
  content: "\f1f6";
}
.ty-icon.ty-icon-task:before {
  content: "\f1f7";
}
.ty-icon.ty-icon-template:before {
  content: "\f1f8";
}
.ty-icon.ty-icon-terrace:before {
  content: "\f1f9";
}
.ty-icon.ty-icon-tiles-off:before {
  content: "\f1fa";
}
.ty-icon.ty-icon-tiles:before {
  content: "\f1fb";
}
.ty-icon.ty-icon-toolbox:before {
  content: "\f1fc";
}
.ty-icon.ty-icon-tools:before {
  content: "\f1fd";
}
.ty-icon.ty-icon-train:before {
  content: "\f1fe";
}
.ty-icon.ty-icon-translate:before {
  content: "\f1ff";
}
.ty-icon.ty-icon-trash:before {
  content: "\f200";
}
.ty-icon.ty-icon-traveler:before {
  content: "\f201";
}
.ty-icon.ty-icon-tree-palm:before {
  content: "\f202";
}
.ty-icon.ty-icon-tree-pine:before {
  content: "\f203";
}
.ty-icon.ty-icon-trophy:before {
  content: "\f204";
}
.ty-icon.ty-icon-trustyou:before {
  content: "\f205";
}
.ty-icon.ty-icon-tuxedo:before {
  content: "\f206";
}
.ty-icon.ty-icon-twitter:before {
  content: "\f207";
}
.ty-icon.ty-icon-undo:before {
  content: "\f208";
}
.ty-icon.ty-icon-unlink:before {
  content: "\f209";
}
.ty-icon.ty-icon-unlock:before {
  content: "\f20a";
}
.ty-icon.ty-icon-upload:before {
  content: "\f20b";
}
.ty-icon.ty-icon-user-search-none:before {
  content: "\f20c";
}
.ty-icon.ty-icon-user-search:before {
  content: "\f20d";
}
.ty-icon.ty-icon-user:before {
  content: "\f20e";
}
.ty-icon.ty-icon-users:before {
  content: "\f20f";
}
.ty-icon.ty-icon-video:before {
  content: "\f210";
}
.ty-icon.ty-icon-warning-o:before {
  content: "\f211";
}
.ty-icon.ty-icon-warning:before {
  content: "\f212";
}
.ty-icon.ty-icon-wechat:before {
  content: "\f213";
}
.ty-icon.ty-icon-whatsapp:before {
  content: "\f214";
}
.ty-icon.ty-icon-widget:before {
  content: "\f215";
}
.ty-icon.ty-icon-wifi:before {
  content: "\f216";
}
.ty-icon.ty-icon-window:before {
  content: "\f217";
}
.ty-icon.ty-icon-word:before {
  content: "\f218";
}
.ty-icon.ty-icon-zoom-in:before {
  content: "\f219";
}
.ty-icon.ty-icon-zoom-out:before {
  content: "\f21a";
}
.ty-icon.ty-icon-viber:before {
  content: "\f21b";
}
.ty-icon.ty-icon-coins:before {
  content: "\f21c";
}
.ty-icon.ty-icon-plus-o:before {
  content: "\f21d";
}
.ty-icon.ty-icon-camera:before {
  content: "\f21e";
}
.ty-icon.ty-icon-inbox-in:before {
  content: "\f21f";
}
.ty-icon.ty-icon-inbox-out:before {
  content: "\f220";
}

.is-exported .ty-icon {
  font-family: Arial, Helvetica, sans-serif;
}
.is-exported .ty-icon.ty-icon-caret-down:before {
  content: '\25BE';
}
.is-exported .ty-icon.ty-icon-caret-up:before {
  content: '\25B4';
}
.is-exported .ty-icon.ty-icon-caret-right:before {
  content: '\25B8';
}
.is-exported .ty-icon.ty-icon-caret-left:before {
  content: '\25C2';
}
.is-exported .ty-icon.ty-icon-arrow-down:before {
  content: '\2193';
}
.is-exported .ty-icon.ty-icon-arrow-up:before {
  content: '\2191';
}
.is-exported .ty-icon.ty-icon-arrow-right:before {
  content: '\2192';
}
.is-exported .ty-icon.ty-icon-arrow-left:before {
  content: '\21';
}
.is-exported .ty-icon.ty-icon-minus:before {
  content: '-';
}
